import React from 'react'
import './NewMark.css'
function NewMark () {
    return(
        <div className="new">
            <h1 className="new-text">new</h1>
        </div>
    )
} 

export default NewMark