import React, { useState, useEffect } from 'react';
import Header from '../components/header/Header';
import LiveCoins from '../components/live-coins/LiveCoins';
import TrendingCoinsList from '../components/trending/TrendingCoinsList';

function Home() {
    const [isFavTab, setIsFavTab] = useState(false);

    return (
        <>
            <Header setIsFavTab={setIsFavTab} isFavTab={isFavTab} />
            <div className="page-content">
                <LiveCoins isFavTab={isFavTab} />
                <TrendingCoinsList />
            </div>
        </>
    );
}

export default Home;
