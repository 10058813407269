import React, { useState, useEffect } from 'react';
import './TrendingCoins.css';
import TrendingCoin from './TrendingCoin';

function TrendingCoinsList() {
    const [gainers, setGainers] = useState([]);
    const [isWideEnough, setIsWideEnough] = useState(window.innerWidth >= 600);
    let key = 0

    useEffect(() => {
        fetch('https://yq0eke7wa8.execute-api.us-east-1.amazonaws.com/trending')
            .then(response => response.json())
            .then(data => {
                setGainers(data.gainers);
            })
            .catch(error => {
                console.error('Error fetching trending data:', error);
            });
        const handleResize = () => {
            setIsWideEnough(window.innerWidth >= 600);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {isWideEnough && (
                <div className="trending-section">
                    <h1 className="trending-title">trending coins</h1>
                    {gainers.map((coin, index) => (
                        <TrendingCoin key={index} coin={coin} />
                    ))}
                </div>
            )}
        </>
    );
}

export default TrendingCoinsList;
