import { useState } from 'react';
import React from 'react';
import './Modal.css';

function Modal({ closeLoginModal, handleLogin }) {

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            closeLoginModal();
        }
    };

    const [formData, setFormData] = useState({
        username: "",
        password: "",
    });

    const [success, setSuccess] = useState(true);

    function handleChange(event) {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }

    async function handleSubmit(e) {
        e.preventDefault();  // Prevent form submission
        try {
            const loginSuccessful = await handleLogin(formData);
            if (loginSuccessful) {
                setSuccess(true);
            } else {
                setSuccess(false);
            }
        } catch (err) {
            console.error('Error:', err);
            setSuccess(false);
        }
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    }

    return (
        <div className="register-modal-container" onClick={handleBackgroundClick}>
            <div className="register-modal" onClick={(e) => e.stopPropagation()}>
                <h1 className="register-text">login to ethersearch</h1>
                <form className="register-form" onKeyPress={handleKeyPress}>
                    <label>
                        <input className="username-input" placeholder="username" type="text" name="username" onChange={handleChange} value={formData.username} />
                    </label>
                    <label>
                        <input className="password-input" placeholder="password" type="password" name="password" onChange={handleChange} value={formData.password} />
                    </label>
                    <button onClick={handleSubmit} className="submit-button" type="button">login</button>
                </form>
                {!success && <h2 className="login-failed">invalid login</h2>}
            </div>
        </div>
    );
}

export default Modal;
